<template>
  <div>
    <BaseTableHeader
      @searchChange="searchChange"
      @refresh="refresh"
    />

    <BaseTable
      :headers="headers"
      :items="clients"
      :search="search"
      rowKey="clientId"
      @rowClick="rowClick"
    />

  </div>
</template>

<script>
import { headers } from '@/components/config/credsClient';
import baseTableMixin from '@/components/mixins/workspace/baseTableMixin';

export default {
  name: 'ServerCredsTable',
  mixins: [baseTableMixin],
  props: {
    clients: Array,
  },
  data: () => ({
    headers,
  }),
};
</script>
